import * as React from "react";
import { Session } from "./datamodel/session";

export interface ISessionContext {
  session?: Session;
  setSession: (session?: Session) => void;
}

export const SessionContext = React.createContext<ISessionContext>({
  session: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSession: () => {},
});
