import { RichTextEditor } from "./components/forms/RichTextEditor/RichTextEditor";
import { MdrGeneralCaseInfoCard } from "./components/cards/MdrGeneralCaseInfoCard/MdrGeneralCaseInfoCard";
import "core-js/stable";

import * as angular from "angular";

import asComponent from "./utils/asComponent";
import asRootComponent from "./utils/asRootComponent";

import Checkbox from "@tds/core-checkbox";
import ChevronLink from "@tds/core-chevron-link";
import Heading from "@tds/core-heading";
import Radio from "@tds/core-radio";
import Spinner from "@tds/core-spinner";
import StandaloneIcon from "@tds/core-standalone-icon";
import DecorativeIcon from "@tds/core-decorative-icon";
import { Tooltip } from "@telus-uds/components-web";
import Link from "@tds/core-link";

import ToggleSwitch from "@tds/community-toggle-switch";

import {
  NotificationSuccess,
  NotificationError,
  NotificationWarning,
} from "@tds/core-feedback-icon";

import { ServiceCheckbox } from "./components/forms/service-checkbox/ServiceCheckbox";
import { DocumentPopoverContent } from "./components/documents/DocumentPopoverContent";
import { StatusPageComponentWidget } from "./components/dashboard-widgets/status-page-component-widget/StatusPageComponentWidget";
import { SrOnlyLabeledCheckbox } from "./components/forms/sr-only-labeled-checkbox/SrOnlyLabeledCheckbox";

import { Topbar } from "./components/layout/Topbar/Topbar";
import { MainNav } from "./components/layout/MainNav/MainNav";
import { Footer } from "./components/layout/Footer/Footer";
import { HtmlContent } from "./components/layout/HtmlContent/HtmlContent";
import { OneLoginStatusWidget } from "./components/dashboard-widgets/one-login-status-widget/OneLoginStatusWidget";
angular
  .module("sherlock-ui-component", [])
  .component(
    "shCheckbox",
    asComponent(Checkbox as React.ComponentType<unknown>, {
      disabled: "<",
    })
  )
  .component(
    "shChevronLink",
    asComponent(ChevronLink as React.ComponentType<unknown>)
  )
  .component("shHeading", asComponent(Heading as React.ComponentType<unknown>))
  .component("shRadio", asComponent(Radio as React.ComponentType<unknown>))
  .component("shSpinner", asComponent(Spinner as React.ComponentType<unknown>))
  .component(
    "shStandaloneIcon",
    asComponent(StandaloneIcon as React.ComponentType<unknown>)
  )
  .component(
    "shDecorativeIcon",
    asComponent(DecorativeIcon as React.ComponentType<unknown>)
  )
  .component(
    "shTooltip",
    asRootComponent(Tooltip as React.ComponentType<unknown>, [
      "content",
      "copy",
      "position",
      "inline",
    ])
  )
  .component("shLink", asComponent(Link as React.ComponentType<unknown>))
  .component(
    "shToggleSwitch",
    asComponent(ToggleSwitch as React.ComponentType<unknown>)
  )
  .component(
    "shFeedbackIconNotificationSuccess",
    asComponent(NotificationSuccess as React.ComponentType<unknown>)
  )
  .component(
    "shFeedbackIconNotificationError",
    asComponent(NotificationError as React.ComponentType<unknown>)
  )
  .component(
    "shFeedbackIconNotificationWarning",
    asComponent(NotificationWarning as React.ComponentType<unknown>)
  )
  .component(
    "shServiceCheckbox",
    asComponent(ServiceCheckbox, {
      acronym: "<",
      label: "<",
      name: "<",
      value: "<",
      error: "<",
      feedback: "<",
      color: "<",
      id: "<",
      disabled: "<",
    })
  )
  .component(
    "shSrOnlyLabeledCheckbox",
    asComponent(SrOnlyLabeledCheckbox, {
      checkmark: "<",
      checkmarkTitle: "<",
      checkmarkTabIndex: "<",
      checkmarkInverted: "<",
      label: "<",
      name: "<",
      value: "<",
      error: "<",
      feedback: "<",
      id: "<",
      disabled: "<",
    })
  )
  .component(
    "shDocumentPopoverContent",
    asRootComponent(DocumentPopoverContent, ["document"])
  )
  .component(
    "shStatusPageComponentWidget",
    asComponent(StatusPageComponentWidget, { componentStatus: "<" })
  )
  .component(
    "shTopBar",
    asRootComponent(Topbar, [
      "customerSelector",
      "adminMenu",
      "supportMenu",
      "languageMenu",
    ])
  )
  .component(
    "shMainNav",
    asRootComponent(MainNav, ["menu", "subMenu", "supportLink"])
  )
  .component("shFooter", asRootComponent(Footer, ["supportHotline", "version"]))
  .component(
    "shOneLoginStatusWidget",
    asComponent(OneLoginStatusWidget, { componentStatus: "<" })
  )
  .component(
    "shMdrGeneralCaseInfoCard",
    asComponent(MdrGeneralCaseInfoCard, {
      individualCase: "<",
      isTelusUser: "<",
    })
  )
  .component(
    "shRichTextEditor",
    asComponent(RichTextEditor, {
      placeholder: "<",
      initialContent: "<",
      onChange: "<",
      editable: "<",
    })
  )
  .component(
    "shHtmlContent",
    asComponent(HtmlContent, {
      html: "<",
    })
  );
