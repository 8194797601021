import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { slide as Menu } from "react-burger-menu";

import {
  colorTelusPurple,
  colorGreyShuttle,
  colorGreyGainsboro,
} from "@tds/core-colours";
import Responsive from "@tds/core-responsive";

import { SupportBold } from "@tds/core-interactive-icon";

import logo from "./Telus-Logo.svg";

import { padds, devices } from "../../../style/global-style";
import { MenuItem } from "../../../datamodel/navigation";

const MainNavStyle = styled.div`
  color: ${colorGreyShuttle};
  background: white;
  position: sticky;
  top: 3.5rem;
  z-index: 1030;
  width: 100%;
  .scrolled {
    height: 3.5rem;
  }
`;

const MainNavContainer = styled.div`
  background: white;
  height: 5.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${colorGreyGainsboro};

  padding-left: ${padds.LeftRightMobile};
  padding-right: ${padds.LeftRightMobile};

  @media ${devices.tablet} {
    padding-left: ${padds.LeftRightTablet};
    padding-right: ${padds.LeftRightTablet};
  }

  @media ${devices.laptop} {
    padding-left: ${padds.LeftRight};
    padding-right: ${padds.LeftRight};
  }
`;

const LogoContainer = styled.div`
  padding-right: 1rem;
  padding-left: 0.2rem;

  img {
    width: 260px;
    max-width: 60vw;
    margin-top: 0.4rem;
  }

  @media ${devices.tablet} {
    padding-left: 1rem;
  }

  @media ${devices.laptop} {
    padding-left: 1rem;
    padding-right: 2rem;
  }

  @media ${devices.laptopL} {
    img {
      width: 300px;
    }
  }

  .scrolled {
    width: 250px;
  }
`;

const MainNavLeftSide = styled.div`
  display: flex;
  align-items: center;

  nav {
    a {
      text-decoration: none;
      color: ${colorGreyShuttle};
    }

    ul {
      display: flex;
    }

    li {
      height: 5.5rem;
      display: flex;
      align-items: center;
      padding-bottom: ${padds.p4};
      padding-left: ${padds.p16};
      padding-right: ${padds.p16};
      white-space: nowrap;
      font-size: 0.9rem;
      @media ${devices.laptopL} {
        font-size: 1rem;
        padding-left: ${padds.p20};
        padding-right: ${padds.p20};
      }

      .scrolled {
        height: 3rem;
      }
    }
    .menuFull {
      @media ${devices.tablet} {
        font-size: 0.85rem;
        padding-left: ${padds.p12};
        padding-right: ${padds.p12};
      }
      @media ${devices.laptop} {
        font-size: 0.85rem;
        padding-left: ${padds.p16};
        padding-right: ${padds.p16};
      }
      @media ${devices.laptopL} {
        font-size: 1rem;
        padding-left: ${padds.p20};
        padding-right: ${padds.p20};
      }
    }

    .active {
      color: ${colorTelusPurple};
      padding-bottom: 0;
      border-bottom: 0.25rem solid ${colorTelusPurple};
      a {
        color: ${colorTelusPurple};
      }
    }

    li:hover {
      color: ${colorTelusPurple};
      padding-bottom: 0;
      border-bottom: 0.25rem solid ${colorTelusPurple};
      a {
        color: ${colorTelusPurple};
      }
    }
  }
`;

const MainNavRightSide = styled.div`
  display: flex;
  align-items: center;
  background: white;

  a {
    text-decoration: none;
    font-size: 0.9rem;
  }

  .bm-burger-button {
    position: relative;
    width: 30px;
    height: 22px;
  }

  .scrolled {
    .bm-burger-button {
      position: relative;
      width: 26px;
      height: 20px;
      top: 14px;
    }
  }
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 32px;
    width: 32px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: ${colorTelusPurple};
    width: 5px !important;
    height: 19px !important;
    border-radius: 11px;
  }

  .bm-burger-bars {
    background: ${colorTelusPurple};

    border-radius: 3px;
  }
  .bm-menu-wrap {
    position: fixed;
    z-index: 9999;
    height: 100%;
    top: 3.5rem;
    border-left: 1px solid ${colorGreyGainsboro};
    -webkit-box-shadow: -3px 0px 9px 1px rgba(0, 0, 0, 0.061);
    box-shadow: -3px 0px 9px 1px rgba(0, 0, 0, 0.061);
  }
  .bm-menu {
    background: white;
    padding: 2.5em 1em 0;
  }
  .bm-item {
    border-left: 4px solid ${colorGreyGainsboro};
    padding-left: 2rem;
    padding-top: 0.8rem;
    padding-bottom: 0.9rem;
    border-bottom: 1px solid ${colorGreyGainsboro};
  }
  .bm-item:hover {
    border-left: 4px solid ${colorTelusPurple};
  }
  .bm-item:active {
    border-left: 4px solid ${colorTelusPurple};
  }
`;

const SubMenuStyle = styled.div`
  min-height: 3.8rem;
  display: flex;

  align-items: center;
  width: 100%;
  padding-left: ${padds.p8};
  padding-right: ${padds.p8};
  border-bottom: 1px solid ${colorGreyGainsboro};
  @media ${devices.mobileM} {
    padding-left: ${padds.p20};
    padding-right: ${padds.p20};
  }
  @media ${devices.mobileL} {
    padding-left: ${padds.p40};
    padding-right: ${padds.p40};
  }
  nav {
    width: 100%;
    padding-left: ${padds.p4};
    padding-right: ${padds.p4};
    a {
      text-decoration: none;
      color: ${colorGreyShuttle};
    }

    ul {
      display: flex;
      width: 100%;
      justify-content: space-between;
      @media ${devices.tablet} {
        justify-content: flex-start;
      }
    }

    li {
      height: 3.8rem;
      display: flex;
      align-items: center;
      padding-left: ${padds.p4};
      padding-right: ${padds.p4};
      padding-bottom: ${padds.p4};
      white-space: nowrap;
      font-size: 0.8rem;
      @media ${devices.mobileL} {
        font-size: 0.85rem;
        padding-left: ${padds.p8};
        padding-right: ${padds.p8};
      }
      @media ${devices.tablet} {
        font-size: 0.9rem;
        padding-left: 0;
        padding-right: ${padds.p20};
      }
    }

    li:hover {
      color: ${colorTelusPurple};
      padding-bottom: 0;
      border-bottom: 0.25rem solid ${colorTelusPurple};
      a {
        color: ${colorTelusPurple};
      }
    }
  }
`;

export declare interface MainNavProps {
  menu: MenuItem[];
  subMenu?: MenuItem[];
  supportLink: string;
}

export const MainNav: React.FC<MainNavProps> = (props) => {
  const { t } = useTranslation();

  /* handle page scrolling for reduced size navbar*/
  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;

    if (offset > 64) {
      setScrolled(true);
    } else if (offset < 32) {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  /* take menu items from prop & generate list */
  const menuItems = props.menu;
  const menuLength = Object.keys(menuItems).length;

  const menuList = menuItems.map((item) => (
    <a href={item.link}>
      <li
        key={item.label}
        className={`
        ${scrolled ? "scrolled" : ""}
        ${menuLength === 8 ? "menuFull" : ""}
        ${item.active ? "active" : ""}
        `}
      >
        {item.label}
      </li>
    </a>
  ));
  /* generate div instead of list on menu */
  const menuListMobile = menuItems.map((item, index) => (
    <a key={index} href={item.link}>
      {item.label}
    </a>
  ));

  /* submenu for inner page nav */
  const subMenuItems = props.subMenu;

  /* menu for desktop */
  function MenuDesktop() {
    return (
      <nav id="navbar">
        <ul>{menuList}</ul>
      </nav>
    );
  }

  /* submenu for desktop */
  function SubMenuDesktop() {
    if (subMenuItems) {
      const subMenuList = subMenuItems.map((item, index) => (
        <a key={index} href={item.link}>
          <li key={item.label}>{item.label}</li>
        </a>
      ));

      return (
        <SubMenuStyle>
          {" "}
          <nav>
            <ul>{subMenuList}</ul>
          </nav>
        </SubMenuStyle>
      );
    } else {
      return null;
    }
  }

  return (
    <MainNavStyle>
      <MainNavContainer className={`${scrolled ? "scrolled" : ""}`}>
        <MainNavLeftSide>
          <LogoContainer>
            <a href="#">
              <img src={logo} alt="logo" />
            </a>
          </LogoContainer>
          <Responsive minWidth="lg" render={() => <MenuDesktop />} />
        </MainNavLeftSide>
        <MainNavRightSide>
          <Responsive
            maxWidth="lg"
            render={() => (
              <div className={`${scrolled ? "scrolled" : ""}`}>
                <Menu right noOverlay>
                  {menuListMobile}

                  <a href={props.supportLink}> {t("Support")}</a>
                </Menu>
              </div>
            )}
          />

          <Responsive
            minWidth="lg"
            render={() => (
              <a href={props.supportLink}>
                <SupportBold copy="en" />
              </a>
            )}
          />
        </MainNavRightSide>
      </MainNavContainer>
      <SubMenuDesktop />
    </MainNavStyle>
  );
};
